import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue)
import App from './App';
import router from './router';
import store from './store';
import { sync } from 'vuex-router-sync';
sync(store, router);
import Resource from 'vue-resource';
Vue.use(Resource);
import * as filters from './filters';
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

const { state } = store;
import VueNativeSock from 'vue-native-websocket';
import auth from './auth';
auth.checkAuth();

Vue.http.interceptors.push(function(request, next) {
  // Add Authorization to all requests
  request.headers.set('Authorization', auth.getAuthHeader());
  if (request.url !== 'api/token/refresh') {}
  // Check for expired token response, if expired, refresh token and resubmit original request
  next(function(response) {}, response => {
    console.log('http request error: ', response)
  });
}.bind(this));

const IS_LOCAL = process.env.NODE_ENV !== 'production';
if (IS_LOCAL) {
  Vue.config.devtools = true;
}
router.beforeEach((to, from, next) => {
  var access_token = localStorage.getItem('access_token');
  var ws_url = '';
  next();
});

/*
  Vue.config.warnHandler = function(msg, vm, trace) {
    // console.log('vue warnHandler: vm => ', vm);
    // console.log('vue warnHandler: msg => ', msg);
    // console.log('vue warnHandler: trace => ', trace.toString());
    if (error_list.indexOf(msg) === -1) {
      error_list.push(msg);
      try {
        let error_data = {
          'codeType': 'frontend',
          'type': 'log',
          'info': trace,
          'sub_type': 'error',
          'typeError': 'frontend',
          'valueError': msg
        };
        // console.log(error_data);
        Vue.http.post(API_BASE_URL + 'log/frontend_log', error_data).then(response => {
          // console.log('response => ', response);
        }, response => {
          // HERE WORKS WHEN WE DONT HAVE AN INTERNET CONNECTION
          console.log('Frontend error sending error. Possible internet connection problem');
        });
      } catch (err) {
        console.log('Vue.config.warnHandler: ', err);
      }
    }
  }
*/

// ------- LANG ------------
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import wdm16 from './wdm16';
let vue_i18n_data = {
  'locale': 'wdm16',
  'fallbackLocale': 'wdm16',
  'messages': { 'wdm16': wdm16 }
};
const i18n = new VueI18n(vue_i18n_data);

const app = new Vue({
  el: '#app',
  router,
  i18n,
  store,
  template: '<App/>',
  components: {
    App
  }
})
export { app, router, store };
