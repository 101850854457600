const StoreApp = state => state.app;
const StoreDevice = state => state.app.device;
const StoreLang = state => state.app.lang;
const StoreLoading = state => state.app.loading;
const StoreModal = state => state.app.modal;
const StorePatient = state => state.app.patient;
const StoreScreen_size = state => state.app.screen_size;
const StoreSidebar = state => state.app.sidebar;
const StoreUser = state => state.app.user;
const StoreVersion = state => state.app.version;
const StoreWebsocket = state => state.app.websocket;

export {
  StoreApp,
  StoreDevice,
  StoreLang,
  StoreLoading,
  StoreModal,
  StorePatient,
  StoreScreen_size,
  StoreSidebar,
  StoreUser,
  StoreVersion,
  StoreWebsocket,
};
