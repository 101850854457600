import Vue from 'vue'
import Router from 'vue-router'
import menuModule from '@/store/modules/menu';
import _menu from '@/_menu';

const defaultcontainer = () => import('@/containers/defaultcontainer');
const login = () => import('@/views/pages/login');
const logout = () => import('@/views/pages/logout');

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [{
      path: '/',
      redirect: _menu.website_data.routes.dashboard.path,
      name: 'defaultcontainer',
      component: defaultcontainer,
      children: [
        ...f_getSideBarMenu(),
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: login,
    }, {
      path: '/logout',
      name: 'logout',
      component: logout,
    }
  ]
})

function f_getSideBarMenu() {
  let side_bar_menu = [];
  for (let i in _menu.website_data.page_list) {
    if (_menu.website_data.page_list[i].route_path) {
      const new_item = {
        'path': _menu.website_data.page_list[i].route_path,
        'component': () => import('../views/pages/' + _menu.website_data.page_list[i].route_name),
        'name': _menu.website_data.page_list[i].route_name
      };
      side_bar_menu.push(new_item);
    }
    if (_menu.website_data.page_list[i].sub_menu && _menu.website_data.page_list[i].sub_menu.length > 0) {
      for (let sub_i in _menu.website_data.page_list[i].sub_menu) {
        if (_menu.website_data.page_list[i].sub_menu[sub_i].route_path) {
          const new_item = {
            'path': _menu.website_data.page_list[i].sub_menu[sub_i].route_path,
            'component': () => import('../views/pages/' + _menu.website_data.page_list[i].sub_menu[sub_i].route_name),
            'name': _menu.website_data.page_list[i].sub_menu[sub_i].route_name
          };
          side_bar_menu.push(new_item);
        }
      }

    }
  }
  // console.log('_menu.website_data.routes.dashboard.path : ', _menu.website_data.routes.dashboard.path);
  // console.log(side_bar_menu);
  return side_bar_menu;
}
