import { default as router } from '@/router';
import Vue from 'vue';
import wdm16 from '@/wdm16';

// URL and endpoint constants
import { API_BASE_URL, CLIENT_ID, CLIENT_SECRET, GRANT_TYPE } from '@/config';
const LOGIN_URL = API_BASE_URL + 'v1/oauth/token';
const LOGOUT_URL = API_BASE_URL + 'v1/oauth/logout';
import LoginService from '@/services/login';
// import LanguageService from '@/services/language';
// import VersionService from '@/services/version';
import store from '@/store';

/* global localStorage */

export default {

  // User object will let us check authentication status
  user: {
    authenticated: false
  },

  // Send a request to the login URL and save the returned JWT
  login(context, creds, redirect) {
    return new Promise((resolve, reject) => {
      creds = Object.assign(creds, {
        'client_id': CLIENT_ID,
        'client_secret': CLIENT_SECRET,
        'grant_type': GRANT_TYPE,
        'scope': 'wisdom'
      });
      // store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Sistem verileri hazırlanıyor lütfen bekleyiniz.' } });
      context.$http.post(LOGIN_URL, creds)
        .then(response => {
          // console.log('response.data : ', response.data);
          localStorage.setItem('access_token', response.data.access_token);
          let wmanager_local_storage = {
            'access_token': response.data.access_token,
            'refresh_token': response.data.refresh_token
          };
          localStorage.setItem('wmanager', JSON.stringify(wmanager_local_storage));
          LoginService.login()
            .then(resp => {
              // console.log(resp.data);
              if (resp.data.status_code === '12000') {
                this.user.authenticated = true;
                localStorage.setItem('user', JSON.stringify(resp.data.user));
                store.commit('MutationUser', { 'status': 1, 'data': resp.data.user });
                if (redirect) {
                  router.push(redirect);
                }
                // store.commit('MutationLoading', { 'status': false, 'data': { 'label': '' } });
              } else {
                // store.commit('MutationLoading', { 'status': false, 'data': { 'label': '' } });
                context.error = response.data.error;
                alert(context.error);
              }
            }), resp => {
              reject('error');
            };
          /*
            LoginService.login_operation({ 'username': creds.username })
              .then(resp => {
                // console.log(resp.data);
                if (resp.data.status_code === '12000') {
                  let login_data = resp.data.result;
                  WmanagerApi.get_user_access_token(creds)
                    .then(resp => {
                      let wmanager_local_storage = {
                        'access_token': resp.access_token,
                        'refresh_token': resp.refresh_token
                      };
                      localStorage.setItem('wmanager', JSON.stringify(wmanager_local_storage));
                      for (let st in login_data.store) {
                        store.commit(st, JSON.stringify(login_data.store[st]));
                      }
                      for (let local in login_data.localStorage) {
                        if (local === 'account_list') {
                          if (!localStorage.getItem('account_list')) {
                            localStorage.setItem(local, JSON.stringify(login_data.localStorage[local]));
                          }
                        } else {
                          localStorage.setItem(local, JSON.stringify(login_data.localStorage[local]));
                        }
                      }
                      this.user.authenticated = true;
                      if (redirect) {
                        router.push(redirect);
                      }
                      store.commit('MutationLoading', { 'status': false, 'data': { 'label': '' } });
                      resolve('success');
                    }, resp => {
                      reject('error, wmanager login');
                    });
                } else {
                  store.commit('MutationLoading', { 'status': false, 'data': { 'label': '' } });
                  context.error = response.data.error;
                  alert(context.error);
                }
              }), resp => {
                reject('error');
              };
          */
        }, response => {
          reject('error');
          store.commit('MutationLoading', { 'status': false, 'data': { 'label': '' } });
          context.error = response.data.error;
          alert(context.error);
        });
    });
  },


  logout(context = '', redirect = '') {
    // console.log(context, redirect);
    if (localStorage.getItem('user')) {
      localStorage.removeItem('user');
    }
    if (localStorage.getItem('wdm_version')) {
      localStorage.removeItem('wdm_version');
    }
    if (localStorage.getItem('wdm22')) {
      localStorage.removeItem('wdm22');
    }
    if (localStorage.getItem('all_users')) {
      localStorage.removeItem('all_users');
    }
    if (localStorage.getItem('analyze_in_wdmr_edit')) {
      localStorage.removeItem('analyze_in_wdmr_edit');
    }
    if (localStorage.getItem('modules')) {
      localStorage.removeItem('modules');
    }
    if (localStorage.getItem('chemotherapy_protocols')) {
      localStorage.removeItem('chemotherapy_protocols');
    }
    if (localStorage.getItem('male_anatomy')) {
      localStorage.removeItem('male_anatomy');
    }
    if (localStorage.getItem('hospital_data')) {
      localStorage.removeItem('hospital_data');
    }
    if (localStorage.getItem('presentation')) {
      localStorage.removeItem('presentation');
    }
    if (localStorage.getItem('wisdomera_system')) {
      localStorage.removeItem('wisdomera_system');
    }
    if (localStorage.getItem('anatomy_all_in_one')) {
      localStorage.removeItem('anatomy_all_in_one');
    }
    if (localStorage.getItem('active_lang')) {
      localStorage.removeItem('active_lang');
    }
    if (localStorage.getItem('version')) {
      localStorage.removeItem('version');
    }
    if (localStorage.getItem('access_token')) {
      localStorage.removeItem('access_token');
    }
    if (localStorage.getItem('wmanager')) {
      localStorage.removeItem('wmanager');
    }
    if (localStorage.getItem('account_list')) {
      localStorage.removeItem('account_list');
    }
    if (localStorage.getItem('profile_picture')) {
      localStorage.removeItem('profile_picture');
    }
    this.user.authenticated = false;
    if (redirect) {
      router.push(redirect);
    }
    /*
      context.$http.post(LOGOUT_URL, { 'logout': true })
        .then(data => {
          // console.log(data);
          if (localStorage.getItem('user')) {
            localStorage.removeItem('user');
          }
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
          }
          if (localStorage.getItem('wmanager')) {
            localStorage.removeItem('wmanager');
          }
          if (localStorage.getItem('account_list')) {
            localStorage.removeItem('account_list');
          }
          if (localStorage.getItem('profile_picture')) {
            localStorage.removeItem('profile_picture');
          }
          this.user.authenticated = false;
          if (redirect) {
            router.go(redirect);
          }
          // location.reload();
        }, response => {
          router.push(redirect);
          context.error = response.data.error;
        });
    */
  },

  checkAuth() {
    var jwt = localStorage.getItem('access_token');
    if (jwt) {
      this.user.authenticated = true;
    } else {
      this.user.authenticated = false;
    }
  },

  // The object to be passed as a header for authenticated requests
  getAuthHeader() {
    return 'Bearer ' + localStorage.getItem('access_token');
  },

  getAuthHeaderWmanager() {
    let wmanager = JSON.parse(localStorage.getItem('wmanager'));
    if (wmanager && wmanager.access_token) {
      return 'Bearer ' + wmanager.access_token;
    } else {
      return '';
    }
  },

  // In Auth service
  refreshToken(context, request) {
    return new Promise(function(resolve, reject) {
      // Refresh token
      var creds = {
        'client_id': CLIENT_ID,
        'client_secret': CLIENT_SECRET,
        'grant_type': 'refresh_token',
        'refresh_token': 'token',
        'scope': 'wisdom'
      };
      Vue.http.post(LOGIN_URL, creds).then(response => {
        // Store refreshed token
        localStorage.setItem('access_token', response.data.access_token);
        // Resubmit original request and resolve the response (probably shouldn't be the responsibility of the Auth service...)
        Vue.http(request).then(function(newResponse) {
          resolve(newResponse);
        });
      }, function(newResponse) {
        reject(newResponse);
      });
    });
  },

  checkExpiredToken(response) {
    return new Promise(function(resolve, reject) {
      // If token is expired, refresh token, resubmit original request & resolve response for original request
      if (response.status === 401 && (response.data.error === 'token_expired' || response.data.error === 'token_invalid')) {
        if (localStorage.getItem('user')) {
          localStorage.removeItem('user');
        }
        if (localStorage.getItem('account_list')) {
          localStorage.removeItem('account_list');
        }
        if (localStorage.getItem('profile_picture')) {
          localStorage.removeItem('profile_picture');
        }
        if (localStorage.getItem('access_token')) {
          localStorage.removeItem('access_token');
          this.checkAuth();
        }
        router.push('/login');
        this.refreshToken(this.request).then(function(response) {
          resolve(response);
        });
      }
      // Otherwise just resolve the current response
      resolve(response);
    }.bind(this));
  },

  checkUnauthorizedToken(response, request_body) {
    // console.log('checkUnauthorizedToken ', response);
    if (response.status === 401 && response.statusText === 'Unauthorized') {
      if (localStorage.getItem('access_token')) {
        localStorage.removeItem('access_token');
        this.checkAuth();
      }
      if (request_body && request_body.to && request_body.token && request_body.refresh) {
        let new_redirect = request_body.to.fullPath;
        if (request_body.to.query.wisdom_token_modsiw) {
          if (new_redirect.indexOf('&wisdom_token_modsiw=' + request_body.to.query.wisdom_token_modsiw) !== -1) {
            new_redirect = new_redirect.replace('&wisdom_token_modsiw=' + request_body.to.query.wisdom_token_modsiw, '');
          } else if (new_redirect.indexOf('wisdom_token_modsiw=' + request_body.to.query.wisdom_token_modsiw + '&') !== -1) {
            new_redirect = new_redirect.replace('wisdom_token_modsiw=' + request_body.to.query.wisdom_token_modsiw + '&', '');
          } else {
            new_redirect = new_redirect.replace('wisdom_token_modsiw=' + request_body.to.query.wisdom_token_modsiw, '');
          }
          delete request_body.to.query.wisdom_token_modsiw;
        }
        if (request_body.to.query.wisdom_refresh_modsiw) {
          if (new_redirect.indexOf('&wisdom_refresh_modsiw=' + request_body.to.query.wisdom_refresh_modsiw) !== -1) {
            new_redirect = new_redirect.replace('&wisdom_refresh_modsiw=' + request_body.to.query.wisdom_refresh_modsiw, '');
          } else if (new_redirect.indexOf('wisdom_refresh_modsiw=' + request_body.to.query.wisdom_refresh_modsiw + '&') !== -1) {
            new_redirect = new_redirect.replace('wisdom_refresh_modsiw=' + request_body.to.query.wisdom_refresh_modsiw + '&', '');
          } else {
            new_redirect = new_redirect.replace('wisdom_refresh_modsiw=' + request_body.to.query.wisdom_refresh_modsiw, '');
          }
          delete request_body.to.query.wisdom_refresh_modsiw;
        }
        router.push('/login?new_url=' + new_redirect);
      } else {
        let url = window.location.href;
        if (url.indexOf('localhost') !== -1) {
          if (router.currentRoute.path !== '/dashboard') {
            router.push('/dashboard');
          }
        } else if (url.indexOf('mlpcare') !== -1) {
          if (router.currentRoute.path !== '/login') {
            router.push('/login');
          }
        } else {
          if (router.currentRoute.path !== '/dashboard') {
            router.push('/dashboard');
          }
        }
      }
    }
  },

  last_wisdomera_version_control: function() {
    let my_version = JSON.parse(localStorage.getItem('version'));
    VersionService.last_wisdomera_version()
      .then(resp => {
        if (resp.data.status === 'success') {
          if (my_version && my_version.version) {
            if (resp.data.result && resp.data.result.version && my_version.version !== resp.data.result.version) {
              store.commit('version', { 'version_data': resp.data.result });
              localStorage.version = JSON.stringify(resp.data.result);
              console.log('last version changed ', my_version.version, ' => ', resp.data.result.version);
            } else {
              store.commit('version', { 'version_data': my_version });
              console.log('no version change');
            }
          } else {
            console.log('version information created: ', resp.data.result.version);
            store.commit('version', { 'version_data': resp.data.result });
            localStorage.version = JSON.stringify(resp.data.result);
          }
        } else {
          console.log('version error:', resp.data.message);
        }
      });
  },

  f_getWisdomeraSystemDocument: function() {
    GlobalService.get_one_cb_document({ 'bucket': 'default', 'key': 'wisdomera_system' })
      .then(resp => {
        if (resp.data.status === 'success') {
          localStorage.setItem('wisdomera_system', JSON.stringify(resp.data.result));
          // console.log(resp.data.result);
        } else {
          console.log('error ', resp.data.message);
        }
      });
  },

  f_getWdm16IdMeaningWithLangId: function(i18n, type = 'default', lang = '') {
    // console.log('f_getWdm16IdMeaningWithLangId, type', type);
    let target_lang = 'en';
    let active_lang = localStorage.getItem('active_lang');
    if (active_lang) {
      target_lang = active_lang;
    }
    if (type === 'default') {
      let user = '';
      try {
        user = JSON.parse(localStorage.getItem('user'));
      } catch (err) {}
      if (user && user.active_lang) {
        target_lang = user.active_lang;
      }
    } else if (type === 'this') {
      if (lang) {
        target_lang = lang;
      }
    }
    // console.log('user.active_lang ', user.active_lang);
    let data = {
      'lang': target_lang,
      'wdm16_list': wdm16
    };
    LanguageService.get_wdm16_id_meaning_with_lang_id(data)
      .then(resp => {
        if (resp.data.status === 'success') {
          localStorage.setItem('active_lang', target_lang);
          // console.log(resp.data.result);
          // wdm16 = resp.data.result.wdm16_list;
          // console.log('nav', nav);
          // console.log(resp.data.result.wdm16_list.wdm16['4053']);
          // i18n.setLocaleMessage(target_lang, resp.data.result.wdm16_list);
          i18n.setLocaleMessage('wdm16', resp.data.result.wdm16_list);
          // console.log(i18n._vm);
          // we translate menu side bar items
          for (let i in nav.items) {
            let w_id = nav.items[i].wdm16_id;
            if (w_id && resp.data.result.wdm16_list['wdm16'][w_id]) {
              // console.log(resp.data.result.wdm16_list['wdm16'][w_id]);
              nav.items[i].name = resp.data.result.wdm16_list['wdm16'][w_id];
            }
            if (nav.items[i].children) {
              for (let c in nav.items[i].children) {
                let w_id = nav.items[i].children[c].wdm16_id;
                if (w_id && resp.data.result.wdm16_list['wdm16'][w_id]) {
                  nav.items[i].children[c].name = resp.data.result.wdm16_list['wdm16'][w_id];
                }
              }
            }
          }
          // i18n.locale = target_lang;
          i18n.locale = 'wdm16';
        } else {
          console.log('error, ', resp.data.message);
        }
      });
  }
};
