import * as MutationTypes from './mutation-types';
export const toggleSidebar = ({ commit }, opened) => commit(MutationTypes.MutationToggleSidebar, opened);
export const toggleDevice = ({ commit }, device) => commit(MutationTypes.MutationToggleDevice, device);
export const expandMenu = ({ commit }, menuItem) => {
  if (menuItem) {
    menuItem.expanded = menuItem.expanded || false;
    commit(MutationTypes.MutationExpandMenu, menuItem);
  }
};
export const toggleLang = ({ commit }, lang) => commit(MutationTypes.MutationLang, lang);
